import {ProductName} from '../interfaces/zchat';

export const ProductNames: {[key: string]: ProductName} = {
  clario: 'clario',
  mackeeper: 'mackeeper',
  zoomsupport: 'zoomsupport',
  mkSoft: 'mkSoft',
  web2app: 'web2app'
};


export const ChatRoomTypes = {
  clarioWeb: 'ClarioWeb',
  preRenewalEmail: 'PreRenewalEmail',
  findAndFixMKSite: 'FindAndFixMKSite',
  ZoomDiagnosticsMac: 'ZoomDiagnosticsMac'
}

export const InitConfigStorageKey = 'zchat_init_config'
