declare var API_CRM_HOST_CLARIO: string;
declare var API_CRM_HOST_MK: string;
declare var API_CHAT_CRM_HOST_CLARIO: string;
declare var API_CHAT_CRM_HOST_MK: string;

export const API_CRM_CLARIO = `${API_CRM_HOST_CLARIO}/chat-api/v2`;
export const API_CRM_MK = `${API_CRM_HOST_MK}/chat-api/v2`;

export const API_CHAT_CRM_CLARIO = `${API_CHAT_CRM_HOST_CLARIO}/v2`;
export const API_CHAT_CRM_MK = `${API_CHAT_CRM_HOST_MK}/chat-api/v2`;

// history
export const API_CHAT_CRM_HISTORY = `${API_CRM_MK}/history/01GMDTECR2W1F72RA6PF0HHPM3/`;

// Authorization
export const API_CRM_AUTHORIZE_CLARIO = `${API_CRM_CLARIO}/lumis/authorize`;
export const API_CRM_AUTHORIZE_MK = `${API_CRM_MK}/authorize/`;

// Room Creation
export const API_CRM_ROOM_CREATE_CLARIO = `${API_CRM_CLARIO}/room/create`;
export const API_CRM__ROOM_CREATE_MK = `${API_CRM_MK}/room/create`;

// Get Refresh Token
export const API_CRM_GET_REFRESH_TOKEN_CLARIO = `${API_CRM_CLARIO}/get-refresh-token`;
export const API_CRM_GET_REFRESH_TOKEN_MK = `${API_CRM_MK}/get-refresh-token`;


export const HeadersContentType = {
  json: 'application/json',
  urlencoded: 'application/x-www-form-urlencoded',
}

