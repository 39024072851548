import {ProductNames} from '../../../constants';

export const ValidationConfigSchema = {
  chatConfig: {
    theme: {
      type: String,
      enum: ['mk', 'clario', 'web2app', 'wta'],
      required: true
    },
    productName: {
      type: String,
      enum: [ProductNames.mackeeper, ProductNames.clario, ProductNames.zoomsupport, ProductNames.mkSoft, ProductNames.web2app],
      required: true,
    },
    showBubble: {
      type: Boolean,
      required: false,
    },
    dnd: {
      type: Boolean,
      required: false,
    },
    rootElement: {
      type: String,
      required: false,
    },
    autoFocus: {
      type: Boolean,
      required: false,
    },
    sendOnEnter: {
      type: Boolean,
      required: false,
    },
    autoConnect: {
      type: Boolean,
      required: false,
    },
    autoOpen: {
      type: Boolean,
      required: false,
    },
    reInitializeOnCloseSession: {
      type: Boolean,
      required: false,
    },
    defaultAgentName: {
      type: String,
      required: true,
    },
    showAgentPhoto: {
      type: Boolean,
      required: true,
    },
    forcedAgentName: {
      type: String,
      required: false,
    },
    agentNameFromHistory: {
      type: Boolean,
      required: true,
    },
    connectRetryLimit: {
      type: Number,
      required: true,
    },
    connectRetryInterval: {
      type: Number,
      required: false,
    },
    messageMinLength: {
      type: Number,
      required: true,
    },
    messageMaxLength: {
      type: Number,
      required: false,
    },
    suggestions: {
      type: Boolean,
      required: false,
    },
    plateTextNeeded: {
      type: Boolean,
      required: false,
    },
    plateText: {
      type: String,
      required: false,
    },
    disconnectedStatusText: {
      type: String,
      required: false,
    },
    scrollPoints: {
      type: Array,
      required: false,
    },
    antiSpyPlate: {
      type: Boolean,
      required: false,
    },
    plateTextVariations: {
      type: Array,
      required: false,
    },
    web2appZChatIteration: {
      type: String,
      required: false,
    },
    automessages: {
      type: Boolean,
      required: false,
    },
    dynamicBubble: {
      type: Boolean,
      required: false,
    },
    automessagesData: {
      startMessage: String,
      required: false,
    },
    surveyStatus: {
      type: String,
      required: false,
    },
    afterSale: {
      type: Boolean,
      required: false,
    },
    surveyProblems: {
      type: Array,
      required: false,
    },
    pricingLink: {
      type: String,
      required: false,
    },
    startChattingTooltip: {
      show: Boolean,
      timeoutToShow: Number,
      showNextTimeAfterClose: Boolean,
    },
    chatExternalEvents: {
      chat_started: String,
      chat_connected: String,
      chat_timeout: String,
      chat_shown: String,
      chat_hidden: String,
      click_link: String,
      chat_closed: String,
    },
    forBlog: {
      type: Boolean,
      required: false,
    },
    forSite: {
      type: Boolean,
      required: false,
    },
  },
  roomConfig: {
    type: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
    affid: {
      type: String,
      required: false,
    },
    lang: {
      type: String,
      required: true,
    },
    sid: {
      type: String,
      required: false,
    },
    mac: {
      type: String,
      required: false,
    },
    deviceId: {
      type: String,
      required: false,
    },
    access_token: {
      type: String,
      required: false,
    }
  }
}
