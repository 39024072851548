import React, {FunctionComponent, useEffect, useState} from 'react';
import {ChatConfig} from '../../interfaces/zchat';
import '../../themes/mk/mk.scss';
import '../../themes/clario/clario.scss';
import '../../themes/mkSoft/mkSoft.scss';
import '../../themes/web2app/web2app.scss';
import '../../themes/wta/wta.scss';
import ChatBubble from '../chatBubble';
import Chat from '../chat';
import ChatClient from '../../chatClient';
import {EventEmitter} from 'events';
import Events from '../../constants/events';
import {MessageInterface, Messages} from '../../store/messages/types';
import {StorageState} from '../../foundations/services/storageStateManager';
import {ChainerInterface} from '../../foundations/utils/chainer';
import {AdaptiveBreakpoint, ConnectionStatus, MessageTypes} from '../../constants';
import {AgentProfile} from '../../store/agent/types';
import isEmptyObject from '../../foundations/utils/isEmptyObject';
import {MessageQueueInterface} from '../../foundations/services/messageQueue';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import getElementCoordinates from '../../foundations/utils/getElementCoordinates';
import ChatBubbleBlog from "../chatBubbleBlog";

type Props = {
  chatConfig: ChatConfig,
  isOpen: boolean;
  agentProfile: AgentProfile | null,
  toggleChat: () => void;
  chatClient: ChatClient | null;
  eventEmitter: EventEmitter;
  messageQueueService: MessageQueueInterface | null;
  setHistory: (messages: Messages) => void;
  messages: Messages | null;
  messageMiddleware: ChainerInterface | null;
  addMessage: (message: MessageInterface, messageType: string) => void;
  setReadMessagesState: () => void;
  setConnectionStatus: (status: ConnectionStatus) => void;
  setAgentProfileInfo: (agentProfile: AgentProfile | null) => void;
  setChatConfig: (config: ChatConfig) => void;
  closeChat: () => void;
  connectionStatus: ConnectionStatus;
}

export const ZchatContext = React.createContext<Partial<ZchatConsumerContext>>({});

export type ZchatConsumerContext = {
  chatClient: ChatClient | null;
  messageMiddleware: ChainerInterface | null;
  eventEmitter: EventEmitter;
  messageQueueService: MessageQueueInterface | null;
  toggleChat: () => void;
}

export function useZchatContext(): Partial<ZchatConsumerContext> {
  const context = React.useContext(ZchatContext);

  if (!context) {
    throw new Error(
      'Zchat Compound Components must be rendered within the App Component',
    )
  }
  return context;
}

const ZchatUI: FunctionComponent<Props> =
  ({
     chatConfig, isOpen, messageMiddleware, setReadMessagesState, setAgentProfileInfo,
     addMessage, toggleChat, chatClient, eventEmitter, setHistory, messages,
     setConnectionStatus, setChatConfig, closeChat, agentProfile, messageQueueService,
     connectionStatus
   }) => {

    useEffect(() => {
      subscribeToChatClientEvents();
      addInternetConnectionStatusListeners();
      setChatConfig(chatConfig);
      eventEmitter.emit(Events.onUIInitialized);
      return () => removeInternetConnectionStatusListeners();
    }, []);

    // Handle case received messages on close chat
    useEffect(() => {
      if (isOpen) {
        setReadMessagesState();
      }
    }, [isOpen]);

    useEffect(() => {
      if (connectionStatus === ConnectionStatus.Connecting && agentProfile) {
        changeConnectionStatus(ConnectionStatus.AgentConnected);
      }

    }, [agentProfile, connectionStatus])

    const addInternetConnectionStatusListeners = () => {
      window.addEventListener('offline', handleOfflineStatus);
      window.addEventListener('online', handleOnlineStatus);
    }

    const removeInternetConnectionStatusListeners = () => {
      window.removeEventListener('offline', handleOfflineStatus);
      window.addEventListener('online', handleOnlineStatus);
    }

    // Subscribe to listeners
    const subscribeToChatClientEvents = () => {
      eventEmitter.on(Events.onGetHistory, function (history) {
        setHistory(history);
      });

      eventEmitter.on(Events.onHide, function () {
          if (StorageState.isOpenState) {
              toggleChat();
          }
      });

      eventEmitter.on(Events.onHideAndScroll, function () {
          if (StorageState.isOpenState) {
              toggleChat();
          }
      });

      eventEmitter.on(Events.onCheckoutOpen, function () {
          if (StorageState.isOpenState) {
              toggleChat();
          }
          messageQueueService?.sendMessage({message: '//checkout_opened', messageType: MessageTypes.comment});
      });

      eventEmitter.on(Events.onCheckoutOpenByUser, function () {
          if (StorageState.roomId) {
              messageQueueService?.sendMessage({message: '//checkout_opened', messageType: MessageTypes.comment});
          }
      });

        eventEmitter.on(Events.onOpenWithButton, function () {
            if (StorageState.roomId) {
                messageQueueService?.sendMessage({message: '//Surveylp_chat_started_intro', messageType: MessageTypes.comment});
            }
        });

      eventEmitter.on(Events.onIncomingMessage, function (message) {
        addMessage(message, MessageTypes.incoming);
      });

      eventEmitter.on(Events.onOutgoingMessage, function (message) {
        addMessage(message, MessageTypes.outgoing);
      });

      eventEmitter.on(Events.onConnecting, function () {
        changeConnectionStatus(ConnectionStatus.Connecting);
      });

      eventEmitter.on(Events.onConnected, function () {
        changeConnectionStatus(ConnectionStatus.Connected);
      });

      eventEmitter.on(Events.onDisconnected, function () {
        changeConnectionStatus(ConnectionStatus.Disconnected);
      });

      eventEmitter.on(Events.onAgentConnected, function (agentProfile) {
        changeConnectionStatus(ConnectionStatus.AgentConnected);
        setAgentProfileInfo(agentProfile);
      });

      eventEmitter.on(Events.onGetAgentProfileInfo, function (agentProfile) {
        if (isEmptyObject(agentProfile)) {
          return;
        }
        changeConnectionStatus(ConnectionStatus.AgentConnected);
        setAgentProfileInfo(agentProfile);
      });

      eventEmitter.on(Events.onBeforeClose, function () {
        closeChat();
      });

      eventEmitter.on(Events.onSessionClose, function () {
        changeConnectionStatus(ConnectionStatus.SessionClose);
      });

      eventEmitter.on(Events.onRoomCreate, function () {
        setAgentProfileInfo(null);
      });
    };

    const changeConnectionStatus = (status: ConnectionStatus) => {
      setConnectionStatus(status);
    }

    const askRoomHistory = (enforce: boolean = false): void => {
      if (enforce || messages == null) {
        (chatClient as ChatClient).askHistory();
        return;
      }
    }

    const restoreChat = (): void => {
      askRoomHistory();
    }

    const handleOfflineStatus = () => {
      changeConnectionStatus(ConnectionStatus.NoInternetConnection);
    }

    const handleOnlineStatus = () => {
      // Handle case if agent send messages before reconnection
      if ((chatClient as ChatClient).isAuthorized()) {
        askRoomHistory();
      }

      if (!(chatClient as ChatClient).isConnected()) {
        changeConnectionStatus(ConnectionStatus.Disconnected);
        return;
      }

      if (!agentProfile) {
        changeConnectionStatus(ConnectionStatus.Connecting)
        return;
      }

      changeConnectionStatus(ConnectionStatus.AgentConnected)
    }

    const showChat = () => {
      if (StorageState.roomId.trim().length) {
        restoreChat();
      }

      toggleChat();
    }

    const toggleChatState = () => {
      eventEmitter.emit(Events.onUserToggleChat, {open: !isOpen});
      showChat();
    }

    const [position, setPosition] = useState({x: 0, y: 0});

    const handleDrag = (event: DraggableEvent, data: DraggableData) => {
      const {x, y, node} = data;

      let {top, left, bottom, right} = getElementCoordinates(node);

      if (top <= 0) {
        setPosition({x, y: y - top});
        return;
      }

      if (bottom <= 0) {
        setPosition({x, y: y + bottom});
        return;
      }

      if (left <= 0) {
        setPosition({x: x - left, y});
        return;
      }

      if (right <= 0) {
        setPosition({x: x + right, y});
        return;
      }

      setPosition({x, y});
    }

    const isDisabled = (): boolean => {
      if (!chatConfig?.dnd) {
        return true;
      }

      const disabledConditions = [`(max-width: ${AdaptiveBreakpoint}px)`, `screen and (max-height: ${AdaptiveBreakpoint}px) and (orientation: landscape)`];

      return disabledConditions.some(condition => window.matchMedia(condition).matches);
    }

    return (
      <ZchatContext.Provider value={{
        chatClient: chatClient,
        messageMiddleware: messageMiddleware,
        messageQueueService: messageQueueService,
        eventEmitter: eventEmitter,
        toggleChat: toggleChatState,
      }}>
        <Draggable
          handle="#draggable"
          onStop={handleDrag}
          position={position}
          disabled={isDisabled()}
        >
          <div
            className={`zchat__wrapper zchat--${chatConfig.theme} zchat--${chatConfig.productName} ${isOpen ? 'zchat__wrapper--open' : 'zchat__wrapper--close'} ${chatConfig.afterSale ? 'zchat__wrapper--aftersale' : ''}`}
          >
            {isOpen && <Chat/>}
          </div>

        </Draggable>
        {chatConfig.forBlog ? <ChatBubbleBlog toggleChat={toggleChatState}/> :  <ChatBubble toggleChat={toggleChatState}/>}
      </ZchatContext.Provider>
    );
  }

export default ZchatUI;
